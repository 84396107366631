import * as React from 'react';

import {useSalesAnnualChart} from "@/features/sales/api/get-sales-annual-chart";
import {MonthlySummaryBarChart} from "@/components/ui/chart/recharts/bar/bar";
import {multipleAxisLabel} from "@/components/ui/chart/charts";
import {colors} from "@/styles/colors";
import {FormatType, formatValue} from "@/utils/formatter";
import {ChartSupplementaryFields} from "@/components/ui/chart/recharts/recharts";
import {useBrand} from "@/app/providers/brand";


export const SalesAnnualChart = ({
    height = "18.5rem",
}: {
    height?: string;
}) => {
    const { brand } = useBrand();

    const salesAnnualChartQuery = useSalesAnnualChart({
        brand: brand,
    })

    if (salesAnnualChartQuery.isLoading) {
        return <div>Loading...</div>;
    }

    if (!salesAnnualChartQuery.data) return null;

    const labels: multipleAxisLabel[] = [
        {
            name: "sales",
            yAxisId: "left",
            color: colors.primary["500"],
            format: FormatType.Currency,
            tooltipReversed: true,
        },
        {
            name: "units",
            yAxisId: "right",
            color: colors.secondary["500"],
            format: FormatType.Integer,
        }
    ];

    // rename data fields if applicable
    const series = salesAnnualChartQuery.data;

    const totalSales = series.reduce((sum, serie) => sum + serie.sales, 0);

    const modifiedSeries = series.map((serie) => serie.sales).reduce<number[]>((acc, sales, index, arr) => {
        if (sales > 0 && (index === 0 || arr[index - 1] !== 0) && index + 1 != series.length) {
            acc.push(sales);
        }
        return acc;
    }, []);

    const totalModifiedSales = modifiedSeries.reduce((sum, serie) => sum + serie, 0);
    const averageModifiedSales = totalModifiedSales / modifiedSeries.length;

    return (
        // <MultipleAxisTimeValueBarChart
        //     title={"Annual Sales Performance"}
        //     labels={[
        //         { name: "Sales", position: "left" },
        //         { name: "Units", position: "right" },
        //     ]}
        //     series={[
        //         salesAnnualChartQuery.data.map(row => [row.date, row.sales]),
        //         salesAnnualChartQuery.data.map(row => [row.date, row.units]),
        //     ]}
        //     height={height}
        //     colors={[
        //         echartColors.green,
        //         echartColors.yellow,
        //     ]}
        // />
        <MonthlySummaryBarChart
            title={"Sales Summary"}
            labels={labels}
            series={series}
            chartSupplementaryFields={(
                <ChartSupplementaryFields
                    mainKPI={formatValue(totalSales, FormatType.Currency)}
                    subtitle={"Average Monthly"}
                    value={formatValue(averageModifiedSales, FormatType.Currency)}
                />
            )}
        />
    );
}